<template>
  <main>
    <PageBreadcrumb v-if="breadcrumb" v-bind="breadcrumb" />
    <slot name="alert"></slot>

    <GsaLogo class="u-padding-top--1 u-padding-bottom--1" />
    <slot name="alerts"></slot>
    <PageBanner v-if="banner" v-bind="banner" />
    <slot></slot>
  </main>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import PageBanner from "../PageBanner/PageBanner.vue";
import PageBreadcrumb from "../PageBreadcrumb/PageBreadcrumb.vue";
import GsaLogo from "../GsaLogo/GsaLogo.vue";
import { PageProps } from "./Props";
import { mapLocalesToHreflangs } from "~/lib/mappers/LocalesToHreflangsMapper";
import locales from "~/config/locales";

export default Vue.extend({
  name: "Page",
  components: {
    PageBreadcrumb,
    PageBanner,
    GsaLogo,
  },
  props: {
    title: {
      type: String as PropType<PageProps["title"]>,
      required: false,
      default: "",
    },
    jsonld: {
      type: Array as PropType<PageProps["jsonld"]>,
      required: true,
    },
    utag: {
      type: Object as PropType<PageProps["utag"]>,
      required: true,
    },
    metadata: {
      type: Array as PropType<PageProps["metadata"]>,
      required: false,
      default: () => [],
    },
    banner: {
      type: Object as PropType<PageProps["banner"]>,
      required: false,
      default: undefined,
    },
    breadcrumb: {
      type: Object as PropType<PageProps["breadcrumb"]>,
      required: false,
      default: undefined,
    },
    defaultTripUrl: {
      type: String as PropType<PageProps["defaultTripUrl"]>,
      required: false,
      default: undefined,
    },
  },
  head() {
    const tealiumScripts = [
      {
        hid: "tealium_config",
        body: true,
        innerHTML: `var utag_data = ${JSON.stringify(this.utag)};`,
        type: "text/javascript",
      },
      {
        hid: "tealium_script",
        body: true,
        innerHTML: `
(function(a,b,c,d){
a='https://tags.tiqcdn.com/utag/intrepid/intrepid/${this.$config.public.tealiumEnvironment}/utag.js';
b=document;c='script';d=b.createElement(c);d.src=a;
d.type='text/java'+c;d.async=true;
a=b.getElementsByTagName(c)[0];a.parentNode.insertBefore(d,a);
})();
`,
        type: "text/javascript",
      },
    ];

    const jsonLd = {
      body: true,
      json: this.$props.jsonld,
      type: "application/ld+json",
    };

    const getCanonicalUrl = () => {
      let canonicalHref = this.$agents.getIsAgentLoggedIn()
        ? this.$config.public.agentsUrl
        : this.$config.public.baseUrl;

      if (this.defaultTripUrl) {
        canonicalHref += "/" + this.$i18n.locale + this.defaultTripUrl;

        if (this.$route?.path.toLowerCase().includes("/tripnotes")) {
          canonicalHref += "/tripnotes";
        }
      } else {
        canonicalHref += this.$route?.path.toLowerCase();
      }

      return {
        rel: "canonical",
        href: canonicalHref,
      };
    };

    const getAlternateLinksForHrefLangs = () => {
      let linkUrl = this.$route?.path.toLowerCase();

      if (this.defaultTripUrl) {
        linkUrl = "/" + this.$i18n.locale + this.defaultTripUrl;

        if (this.$route?.path.toLowerCase().includes("/tripnotes")) {
          linkUrl += "/tripnotes";
        }
      }

      return mapLocalesToHreflangs(
        this.$config.public.baseUrl,
        linkUrl,
        locales
      );
    };

    return {
      __dangerouslyDisableSanitizersByTagID: {
        tealium_config: ["script", "innerHTML"],
        tealium_script: ["script", "innerHTML"],
      },
      link: [getCanonicalUrl(), ...getAlternateLinksForHrefLangs()],
      title: this.title,
      meta: Object.values(this.metadata),
      script:
        this.$route?.query?.performance_test === "true"
          ? [jsonLd]
          : [...tealiumScripts, jsonLd],
    };
  },
  computed: {
    pageType(): string {
      return this.$store.getters["page/getPageType"];
    },
  },
});
</script>

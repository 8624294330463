<template>
  <VisaEntryRequirementsPage
    :headline="headline"
    :introduction="introduction"
    :page="page"
  />
</template>

<script lang="ts">
import Vue from "vue";
import { InitPageParams } from "lib/utils/initPage";
import {
  setNewRelicSpanAttributes,
  setNewRelicTransactionName,
} from "~~/lib/utils/logger/instrument";
import { getBaseLink } from "~~/lib/utils/link/getBaseLink";
import VisaEntryRequirementsPage from "~/components/VisaEntryRequirementsPage/VisaEntryRequirementsPage.vue";
import { initPage } from "~~/lib/utils/initPage";
import { VisaEntryRequirementsPageResponse } from "~~/lib/types/VisaEntryRequirements";
import { loggerFactory, logTags } from "~~/lib/utils/logger/logger";

const logger = loggerFactory({
  tags: [logTags.Layer.Component, logTags.Page.VisaEntryRequirements],
});

export default Vue.extend({
  name: "VisaEntryRequirements",
  components: {
    VisaEntryRequirementsPage,
  },
  async asyncData({
    store,
    i18n,
    route,
    params,
    req,
    $config,
    error,
  }): Promise<VisaEntryRequirementsPageResponse | undefined> {
    try {
      if (req.url?.includes("/__webpack_hmr/client")) {
        error({
          message: "Not found",
          statusCode: 404,
        });
        return;
      }

      const requestParams: InitPageParams = {
        locale_iso: i18n.localeProperties.iso,
        locale_code: i18n.locale,
        locale_domain: $config.public.baseUrl,
        path: route.path,
        slug: getBaseLink(route.path),
        currency_code:
          params.currencyCode || i18n.localeProperties.currencyCode,
        current_path: getBaseLink(route.path),
        page_type: "content",
      };

      setNewRelicTransactionName("VisaEntryRequirements");
      setNewRelicSpanAttributes({
        locale: i18n.locale,
        currencyCode: params.currencyCode || i18n.localeProperties.currencyCode,
        path: route.path,
      });

      const pageContent: VisaEntryRequirementsPageResponse = await $fetch(
        "/api/nuxt/visa-entry-requirements",
        {
          params: requestParams,
        }
      );

      const [
        algoliaPublicSearchKeyDispatcher,
        salesRegionDispatcher,
        notificationAndMenuItemsDispatcher,
      ] = initPage(store, requestParams);

      await Promise.all([
        algoliaPublicSearchKeyDispatcher,
        salesRegionDispatcher,
        notificationAndMenuItemsDispatcher,
      ]);

      return pageContent;
    } catch (error) {
      logger.error(
        "Error occurred in asyncData in visa-entry-requirements.vue",
        error
      );
    }
  },
  data() {
    return {
      headline: {},
      introduction: {},
      page: {},
    };
  },
});
</script>
